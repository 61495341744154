import Bowser from "bowser";

const userId = getUserId();
const sessionId = getSessionId();
const browserData = getBrowserData();

window.addEventListener("load", (event) => {
  if (localStorage.getItem("cookies")) {
    setInitialValues();
  }
});

function getUserId() {
  // Checks if user id exists in store, otherwise generate
  if (!localStorage.getItem("userId")) {
    //Generates userId if local storage is empty
    const generatedNumbers = new Uint8Array(16);
    window.crypto.getRandomValues(generatedNumbers);
    let userId = generatedNumbers.reduce((a, b) => {
      return a + b.toString(16).padStart(2, "0");
    }, "");
    localStorage.setItem("userId", userId);
  } else {
    return localStorage.getItem("userId");
  }
}

function getSessionId() {
  // Generates sessionid
  const generatedNumber = new Uint8Array(8);
  window.crypto.getRandomValues(generatedNumber);
  let sessionId = generatedNumber.reduce((a, b) => {
    return a + b.toString(16).padStart(2, "0");
  }, "");
  return sessionId;
}

function getBrowserData() {
  const userData = Bowser.parse(window.navigator.userAgent);
  const eventData = {
    deviceType: userData.platform.type,
    browserName: userData.browser.name,
    browserVersion: userData.browser.version,
    os: userData.os.name,
    osVersion: userData.os.versionName,
    referrer: document.referrer,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    browserLanguage: window.navigator.language,
  };
  return eventData;
}

export async function setInitialValues() {
  let data = {};

  data.eventData = browserData;
  data.eventType = "Enter";
  data.userId = await getUserId();
  data.sessionId = sessionId;

  fetch("/api/useranalytics", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export const sendPumpId = async (id) => {
  let userSearch = {
    eventData: { pumpId: id },
    userId: await getUserId(),
    sessionId: sessionId,
    eventType: "Search",
  };
  fetch("/api/useranalytics", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(userSearch),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const distributorClick = async (id, originalPumpId) => {
  let distributorButton = {
    eventData: { replacementPumpId: id, originalPumpId: originalPumpId },
    userId: await getUserId(),
    sessionId: sessionId,
    eventType: "Distributor",
  };
  fetch("/api/useranalytics", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(distributorButton),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const adapterClick = async (id, pumpId) => {
  let distributorButton = {
    eventData: { adapter: id, pumpId: pumpId },
    userId: await getUserId(),
    sessionId: sessionId,
    eventType: "Distributor",
  };
  fetch("/api/useranalytics", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(distributorButton),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const accessoryClick = async (id, pumpId) => {
  let accessoryAhlsellButton = {
    eventData: { accessory: id, pumpId: pumpId },
    userId: await getUserId(),
    sessionId: sessionId,
    eventType: "Distributor",
  };
  fetch("/api/useranalytics", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(accessoryAhlsellButton),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
};
const myWorker = new window.Worker("/worker.js");
// Send message to worker
myWorker.postMessage("Hello!");

// Receive message from worker
myWorker.onmessage = function () {
  fetch(`/api/useranalytics/heartbeat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ userId, sessionId }),
  }).catch((error) => {
    console.error("Error:", error);
  });
};
