import { proxy, subscribe } from "valtio";

// State related to graphical rendering
export const ViewState = proxy({
  width: window.innerWidth,
});

window.addEventListener("resize", () => {
  ViewState.width = window.innerWidth;
});

/* window.onload = (event) => {
  MetadataState.country = localStorage.getItem("country");
}; */

// Language and market configuration state
export const LocaleState = proxy({
  language: "",
});

// Metadata that always gets fetched from API
export const MetadataState = proxy({
  adapters: {},
  brands: {},
  pumps: [],
  texts: {},
  phone: "",
  email: "",
  country: "",
});

export const Store = proxy({
  filteredPumps: [],
  existingPump: null,
  existingPumpId: null,
  replacementPumps: [],
  filter: { brand: null },
  fuse: null,
  matchedPumps: [],
});

export const SelectionState = proxy({
  selectedPump: null,
});

export const PumpState = proxy({
  existingPump: null,
  replacementPumps: [],
  pumpAccessory: [],
});

// Fetch metadata
subscribe(LocaleState, () => {
  const language = LocaleState.language;
  const market = MetadataState.country;

  fetch(`/api/metadata/brands?language=${language}&market=${market}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.brands = response;
    });

  fetch(`/api/metadata/pumps?language=${language}&market=${market}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.pumps = response;
    });

  fetch(`/api/metadata/adapters?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.adapters = response;
    });

  fetch(`/api/metadata/texts?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.texts = response;
    });
});

export const fetchPumpData = () => {
  const selectedPump = SelectionState.selectedPump;
  const language = LocaleState.language;
  const market = MetadataState.country;

  if (typeof selectedPump === "string") {
    fetch(`/api/pump/${selectedPump}?language=${language}&market=${market}`)
      .then((response) => response.json())
      .then((response) => {
        PumpState.existingPump = response;
      });

    fetch(
      `/api/pump/replacement/${selectedPump}?language=${language}&market=${market}`
    )
      .then((response) => response.json())
      .then((response) => {
        PumpState.replacementPumps = response;
      });
  } else {
    PumpState.existingPump = null;
    PumpState.replacementPumps = [];
  }
};

export const fetchBrandsAndPumps = () => {
  const language = LocaleState.language;
  const market = MetadataState.country;
  fetch(`/api/metadata/brands?language=${language}&market=${market}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.brands = response;
    });

  fetch(`/api/metadata/pumps?language=${language}&market=${market}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.pumps = response;
    });
};

const fetchLanguages = () => {
  if (MetadataState.country === "DE") {
    fetch(`/api/metadata/texts?language=DE`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "DE";
      });
  }
  if (MetadataState.country === "CH") {
    fetch(`/api/metadata/texts?language=CH`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "CH";
      });
  }
  if (MetadataState.country === "AT") {
    fetch(`/api/metadata/texts?language=AT`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "AT";
      });
  }
  if (MetadataState.country === "SE") {
    fetch(`/api/metadata/texts?language=SE`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "SE";
      });
  }
  if (MetadataState.country === "EN") {
    fetch(`/api/metadata/texts?language=EN`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "EN";
      });
  }
  if (MetadataState.country === "NL") {
    fetch(`/api/metadata/texts?language=NL`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "NL";
      });
  }
  if (MetadataState.country === "IE") {
    fetch(`/api/metadata/texts?language=IE`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "IE";
      });
  }
  if (MetadataState.country === "ES") {
    fetch(`/api/metadata/texts?language=ES`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "ES";
      });
  }
  if (MetadataState.country === "PT") {
    fetch(`/api/metadata/texts?language=PT`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "PT";
      });
  }
  if (MetadataState.country === "CZ") {
    fetch(`/api/metadata/texts?language=CZ`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "CZ";
      });
  }
  if (MetadataState.country === "SK") {
    fetch(`/api/metadata/texts?language=SK`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "SK";
      });
  }
  if (MetadataState.country === "GR") {
    fetch(`/api/metadata/texts?language=GR`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "GR";
      });
  }
  if (MetadataState.country === "AL") {
    fetch(`/api/metadata/texts?language=AL`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "AL";
      });
  }
  if (MetadataState.country === "IL") {
    fetch(`/api/metadata/texts?language=IL`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "IL";
      });
  }
  if (MetadataState.country === "RO") {
    fetch(`/api/metadata/texts?language=RO`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "RO";
      });
  }
  if (MetadataState.country === "PL") {
    fetch(`/api/metadata/texts?language=PL`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "PL";
      });
  }
  if (MetadataState.country === "HU") {
    fetch(`/api/metadata/texts?language=HU`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "HU";
      });
  }
  if (MetadataState.country === "UA") {
    fetch(`/api/metadata/texts?language=UA`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "UA";
      });
  }
  if (MetadataState.country === "EE") {
    fetch(`/api/metadata/texts?language=EE`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "EE";
      });
  }
  if (MetadataState.country === "LV") {
    fetch(`/api/metadata/texts?language=LV`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "LV";
      });
  }
  if (MetadataState.country === "LT") {
    fetch(`/api/metadata/texts?language=LT`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "LT";
      });
  }
  if (MetadataState.country === "AZ") {
    fetch(`/api/metadata/texts?language=AZ`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "AZ";
      });
  }
  if (MetadataState.country === "AM") {
    fetch(`/api/metadata/texts?language=AM`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "AM";
      });
  }
  if (MetadataState.country === "GE") {
    fetch(`/api/metadata/texts?language=GE`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "GE";
      });
  }
  if (MetadataState.country === "KZ") {
    fetch(`/api/metadata/texts?language=KZ`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "KZ";
      });
  }
  if (MetadataState.country === "KG") {
    fetch(`/api/metadata/texts?language=KG`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "KG";
      });
  }
  if (MetadataState.country === "TJ") {
    fetch(`/api/metadata/texts?language=TJ`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "TJ";
      });
  }
  if (MetadataState.country === "TM") {
    fetch(`/api/metadata/texts?language=TM`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "TM";
      });
  }
  if (MetadataState.country === "UZ") {
    fetch(`/api/metadata/texts?language=UZ`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "UZ";
      });
  }
  if (MetadataState.country === "BA") {
    fetch(`/api/metadata/texts?language=BA`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "BA";
      });
  }
  if (MetadataState.country === "BG") {
    fetch(`/api/metadata/texts?language=BG`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "BG";
      });
  }
  if (MetadataState.country === "HR") {
    fetch(`/api/metadata/texts?language=HR`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "HR";
      });
  }
  if (MetadataState.country === "XK") {
    fetch(`/api/metadata/texts?language=XK`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "XK";
      });
  }
  if (MetadataState.country === "MK") {
    fetch(`/api/metadata/texts?language=MK`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "MK";
      });
  }
  if (MetadataState.country === "MD") {
    fetch(`/api/metadata/texts?language=MD`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "MD";
      });
  }
  if (MetadataState.country === "ME") {
    fetch(`/api/metadata/texts?language=ME`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "ME";
      });
  }
  if (MetadataState.country === "RS") {
    fetch(`/api/metadata/texts?language=RS`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "RS";
      });
  }
  if (MetadataState.country === "SI") {
    fetch(`/api/metadata/texts?language=SI`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "SI";
      });
  }
  if (MetadataState.country === "FI") {
    fetch(`/api/metadata/texts?language=FI`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "FI";
      });
  }
  if (MetadataState.country === "NO") {
    fetch(`/api/metadata/texts?language=NO`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "NO";
      });
  }
  if (MetadataState.country === "FR") {
    fetch(`/api/metadata/texts?language=FR`)
      .then((response) => response.json())
      .then((response) => {
        MetadataState.email = response.general.email;
        MetadataState.phone = response.general.phone;
        MetadataState.country = "FR";
      });
  }
};

subscribe(SelectionState, fetchPumpData);
subscribe(LocaleState, fetchPumpData, fetchLanguages);
subscribe(MetadataState, fetchLanguages);
// subscribe(PumpState, fetchAccessories);

const checkCountry = () => {
  const country = localStorage.getItem("country");
  if (!country) localStorage.setItem("country", "SE");
  if (country === "SE") MetadataState.country = "SE";
  if (country === "DE") MetadataState.country = "DE";
  if (country === "CH") MetadataState.country = "CH";
  if (country === "AT") MetadataState.country = "AT";
  if (country === "NL") MetadataState.country = "NL";
  if (country === "EN") MetadataState.country = "EN";
  if (country === "IE") MetadataState.country = "IE";
  if (country === "ES") MetadataState.country = "ES";
  if (country === "PT") MetadataState.country = "PT";
  if (country === "CZ") MetadataState.country = "CZ";
  if (country === "SK") MetadataState.country = "SK";
  if (country === "GR") MetadataState.country = "GR";
  if (country === "AL") MetadataState.country = "AL";
  if (country === "IL") MetadataState.country = "IL";
  if (country === "RO") MetadataState.country = "RO";
  if (country === "PL") MetadataState.country = "PL";
  if (country === "HU") MetadataState.country = "HU";
  if (country === "UA") MetadataState.country = "UA";
  if (country === "EE") MetadataState.country = "EE";
  if (country === "LV") MetadataState.country = "LV";
  if (country === "LT") MetadataState.country = "LT";
  if (country === "AZ") MetadataState.country = "AZ";
  if (country === "AM") MetadataState.country = "AM";
  if (country === "GE") MetadataState.country = "GE";
  if (country === "KZ") MetadataState.country = "KZ";
  if (country === "KG") MetadataState.country = "KG";
  if (country === "TJ") MetadataState.country = "TJ";
  if (country === "TM") MetadataState.country = "TM";
  if (country === "UZ") MetadataState.country = "UZ";
  if (country === "BA") MetadataState.country = "BA";
  if (country === "BG") MetadataState.country = "BG";
  if (country === "HR") MetadataState.country = "HR";
  if (country === "XK") MetadataState.country = "XK";
  if (country === "MK") MetadataState.country = "MK";
  if (country === "MD") MetadataState.country = "MD";
  if (country === "ME") MetadataState.country = "ME";
  if (country === "RS") MetadataState.country = "RS";
  if (country === "SI") MetadataState.country = "SI";
  if (country === "FI") MetadataState.country = "FI";
  if (country === "NO") MetadataState.country = "NO";
  if (country === "FR") MetadataState.country = "FR";
};
const checkLanguage = () => {
  const language = localStorage.getItem("language");
  if (!language) localStorage.setItem("language", "en");
  if (language === "sv") LocaleState.language = "sv";
  if (language === "de") LocaleState.language = "de";
  if (language === "en") LocaleState.language = "en";
  if (language === "nl") LocaleState.language = "nl";
  if (language === "es") LocaleState.language = "es";
  if (language === "pt") LocaleState.language = "pt";
  if (language === "no") LocaleState.language = "no";
  if (language === "fr") LocaleState.language = "fr";
};

checkCountry();
checkLanguage();

const urlParams = window.location.pathname.split("/").toString();
const urlCountryLanguage = urlParams.split(",").slice(1, 2).toString();
const urlCountry = urlCountryLanguage.split("-").slice(1, 2);
const urlLanguage = urlCountryLanguage.split("-").slice(0, 1);

LocaleState.language =
  urlLanguage[0] || localStorage.getItem("language") || "en";

MetadataState.country =
  urlCountry[0] || localStorage.getItem("country") || "SE";
//do we want tot override the users settings?
// localStorage.setItem("country", urlCountry[0]);
// localStorage.setItem("language", urlLanguage[0]);
