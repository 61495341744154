import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

export function Datasheet(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const source = props.src || null;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    if (pageNumber >= numPages) {
      setPageNumber(1);
    }
  };

  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
    if (pageNumber <= 1) {
      setPageNumber(numPages);
    }
  };

  // const downloadDpf = () =>
  //   fetch(source)
  //     .then((response) => response.blob())
  //     .then(function (blob) {
  //       let link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = "Datasheet.pdf";
  //       document.body.appendChild(link);
  //       link.click();
  //     });

  return (
    <div>
      <div className="pageSelection">
        <button onClick={handlePreviousPage} className="switchPageButton">
          <AiOutlineArrowLeft />
        </button>
        <p>
          {pageNumber} / {numPages}
        </p>
        <button onClick={handleNextPage} className="switchPageButton">
          <AiOutlineArrowRight />
        </button>
      </div>
      <div>
        <Document file={source} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      {/* <button className="ModalButton" onClick={(e) => downloadDpf()}>
        Download PDF
      </button> */}
    </div>
  );
}
