import React from "react";
import "../Styles/ChatbotIcon.css";
// import pump from "../old_xylem_pump.png"; // temp image. Replace it with xylem logo.

// ChatbotIcon render.
const ChatbotIcon = ({ onClick }) => {
  return (
    <div className="chatbot-icon" onClick={onClick}>
      <i className="fas fa-comment">GPT-3</i>
      {/* <img src={pump} className="oldPump" alt="old xylem pump" /> */}
    </div>
  );
};

export default ChatbotIcon;
