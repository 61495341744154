import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";

import { Ahlsell } from "./Ahlsell";
import { ViewState, MetadataState, PumpState } from "../store";
import downArrow from "../asset/down_arrow.svg";
import upArrow from "../asset//up_arrow.svg";
import logo from "../asset/logo.png";
import { accessoryClick } from "../api/userAnalytics";

const mobileStyling = {
  marginLeft: "50%",
  transform: "translate(-50%)",
  width: "max-content",
};

const desktopStyling = {
  height: "1rem",
  margin: "0rem",
  width: "max-content",
};

export const Accessories = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const ViewSnap = useSnapshot(ViewState);
  const market = MetadataSnap.country;
  const pumpId = props.pumpId;
  const [accessory, setAccessory] = useState([]);
  const [accIds, setAccIds] = useState([]);

  const formatRSK = (rsk) => {
    rsk += "";
    return (
      rsk.substring(0, 3) +
      " " +
      rsk.substring(3, 5) +
      " " +
      rsk.substring(5, 7)
    );
  };
  useEffect(() => {
    if (pumpId) {
      fetch(`/api/accessories/${pumpId}`)
        .then((response) => response.json())
        .then((response) => {
          setAccessory(response);
          PumpState.pumpAccessory = response;
        });
    }
  }, [pumpId]);

  const showTable = (id) => {
    const isInArray = accIds.includes(id);
    if (isInArray) {
      const arr = accIds.filter((e) => e !== id);
      setAccIds([...arr]);
    } else {
      setAccIds([...accIds, id]);
    }
  };

  return (
    <div>
      {accessory.map((accessories, idx) => {
        const rsk = accessories.accessoryRsk;
        const product = accessories.product;
        const productName = accessories.productName;
        const description = accessories.description;
        const id = accessories.id;
        const image = accessories.image;
        const distributor = accessories.attributes?.distributor;
        const link = distributor || {};
        const ahlsellLink = Object.values(link).map((se) => {
          return se;
        });
        return (
          <React.Fragment key={"accessory_" + idx}>
            <div className="accessoryTab" onClick={() => showTable(id)}>
              {product}{" "}
              <img
                src={"/api/file/" + image}
                alt=""
                style={{ height: "2rem" }}
              />
              <img src={accIds.includes(id) ? upArrow : downArrow} alt="" />
            </div>
            <table className="accessories_table">
              <tbody>
                <React.Fragment key={"accessories_" + idx}>
                  {ViewSnap.width > 800 ? (
                    <>
                      {accIds.includes(id) && (
                        <tr>
                          <th>Produkt</th>
                          <th className="accessoriesWider">Beskrivning</th>
                          <th>RSK</th>

                          {productName && productName !== "" ? (
                            <th>Produktnamn</th>
                          ) : (
                            <th></th>
                          )}

                          {distributor && market === "SE" && (
                            <th className="hideOnPrint">
                              {MetadataSnap.texts.labels.reseller}
                            </th>
                          )}
                        </tr>
                      )}
                      {accIds.includes(id) && (
                        <tr>
                          <td>{product}</td>
                          <td className="accessoriesWider">{description}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {formatRSK(rsk)}
                          </td>

                          <td>{productName}</td>
                          {distributor && market === "SE" && (
                            <td className="hideOnPrint">
                              <Ahlsell
                                distributor={ahlsellLink}
                                desktopStyling={desktopStyling}
                                mobileStyling={mobileStyling}
                                accessoryId={rsk}
                                pumpId={pumpId}
                                accessoryClicked={"accessoryClicked"}
                              />
                            </td>
                          )}
                          <td className="accessoriesImage">
                            {image ? (
                              <img
                                className="accessoryImage"
                                src={"/api/file/" + image}
                                alt=""
                              />
                            ) : (
                              <img
                                className="accessoryImage"
                                src={logo}
                                alt=""
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <React.Fragment>
                      {accIds.includes(id) && (
                        <>
                          <tr>
                            <th>Produkt</th>
                            <td>{product}</td>
                          </tr>
                          <tr>
                            <th>Beskrivning</th>
                            <td>{description}</td>
                          </tr>
                          <tr>
                            <th>RSK</th>
                            <td>{formatRSK(rsk)}</td>
                          </tr>

                          <tr>
                            {productName && productName !== "" ? (
                              <th>Produktnamn</th>
                            ) : (
                              <th></th>
                            )}
                            <td>{productName}</td>
                          </tr>

                          <tr>
                            {distributor && market === "SE" && (
                              <>
                                <th>Återförsäljare</th>
                                <td className="hideOnPrint">
                                  <Ahlsell
                                    distributor={ahlsellLink}
                                    desktopStyling={desktopStyling}
                                    mobileStyling={mobileStyling}
                                    pumpId={pumpId}
                                    accessoryId={rsk}
                                    accessoryClicked={"accessoryClicked"}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                          {image && (
                            <tr>
                              <td
                                style={{ backgroundColor: "white" }}
                                colSpan={2}
                              >
                                <img
                                  src={"/api/file/" + image}
                                  alt=""
                                  style={{
                                    height: "5rem",
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              </tbody>
            </table>
          </React.Fragment>
        );
      })}
    </div>
  );
};
