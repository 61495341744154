import React from "react";
import { useSnapshot } from "valtio";

import { MetadataState } from "../store";

export const Footer = () => {
  const MetadataSnap = useSnapshot(MetadataState);
  return (
    <footer>
      <div>
        <span id="copyrightMessage">
          {MetadataSnap.texts?.general?.copyright}
        </span>
      </div>
    </footer>
  );
};
