import React, { useState, useEffect, useRef } from 'react';
import { useSnapshot } from "valtio";
import '../Styles/Chatbox.css'
import ChatbotIcon from './ChatbotIcon';
import { ReactComponent as Loader } from '../loader.svg';
import { LocaleState } from "../store";


const Chatbox = () => {
  // Track changes in the page. (Language, input value sent by the user, chatbox minimize and maximize, welcome message and wait message.)
  const LocaleSnap = useSnapshot(LocaleState);
  const [inputValue, setInputValue] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [showChatbox, setShowChatbox] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [welcomeContent, setWelcomeContent] = useState("Welcome to Xylem! I am a chatbot powered by GPT3 to help you with your queries related to Xylem."); // Default language is English
  const [waitMessage, setWaitMessage] = useState("Your request has been sent to GPT3. Please wait while we process your request.");
  const [reRenderFlag, setReRenderFlag] = useState(false);
  const chatboxRef = useRef(null);
  
  // Change the welcome message based on the page's language
  useEffect(() => {
    if (LocaleSnap.language === "en") {
      setWelcomeContent("Welcome to Xylem! I am a chatbot powered by GPT3 to help you with your queries related to Xylem. As an AI chatbot, I can respond in many languages. Please converse with me in your preferred language.");
    }
    if (LocaleSnap.language === "sv") {
      setWelcomeContent("Välkommen till Xylem! Jag är en chattbot som drivs av GPT3 för att hjälpa dig med dina frågor relaterade till Xylem. Som en AI-chattbot kan jag svara på många språk. Vänligen prata med mig på ditt föredragna språk.");
    }
    if (LocaleSnap.language === "de") {
      setWelcomeContent("Willkommen bei Xylem! Ich bin ein Chatbot, der von GPT3 betrieben wird, um Ihnen bei Ihren Fragen zu Xylem zu helfen. Als KI-Chatbot kann ich in vielen Sprachen antworten. Bitte unterhalten Sie sich mit mir in Ihrer bevorzugten Sprache.");
    }
    if (LocaleSnap.language === "nl") {
      setWelcomeContent("Welkom bij Xylem! Ik ben een chatbot aangedreven door GPT3 om u te helpen met uw vragen met betrekking tot Xylem. Als AI-chatbot kan ik in veel talen reageren. Praat alstublieft met mij in uw voorkeurstaal.");
    }
    if (LocaleSnap.language === "es") {
      setWelcomeContent("¡Bienvenido a Xylem! Soy un chatbot impulsado por GPT3 para ayudarlo con sus consultas relacionadas con Xylem. Como chatbot de IA, puedo responder en muchos idiomas. Por favor converse conmigo en su idioma preferido.");
    }
    if (LocaleSnap.language === "pt") {
      setWelcomeContent("Bem-vindo à Xylem! Sou um chatbot alimentado por GPT3 para ajudá-lo com suas consultas relacionadas à Xylem. Como chatbot de IA, posso responder em muitos idiomas. Por favor, converse comigo no seu idioma preferido.");
    }
    if (LocaleSnap.language === "pl") {
      setWelcomeContent("Witamy w Xylem! Jestem chatbotem zasilanym przez GPT3, który pomoże Ci w zapytaniach dotyczących Xylem. Jako chatbot AI mogę odpowiadać w wielu językach. Proszę porozmawiać ze mną w wybranym przez siebie języku.");
    }
    setReRenderFlag(true);
  }, [LocaleSnap.language]);

  // Change the wait message based on the page's language 
  useEffect(() => {
    if (LocaleSnap.language === "en") {
      setWaitMessage("Your request has been sent to GPT3. Please wait while we process your request.");
    }
    if (LocaleSnap.language === "sv") {
      setWaitMessage("Din begäran har skickats till GPT3. Vänta medan vi behandlar din förfrågan.");
    }
    if (LocaleSnap.language === "de") {
      setWaitMessage("Ihre Anfrage wurde an GPT3 gesendet. Bitte warten Sie, während wir Ihre Anfrage bearbeiten.");
    }
    if (LocaleSnap.language === "nl") {
      setWelcomeContent(" Uw verzoek is verzonden naar GPT3. Even geduld terwijl we uw verzoek verwerken.")
    }
    if (LocaleSnap.language === "es") {
      setWaitMessage("Su solicitud ha sido enviada a GPT3. Espere mientras procesamos su solicitud.");
    }
    if (LocaleSnap.language === "pt") {
      setWaitMessage("Sua solicitação foi enviada para GPT3. Aguarde enquanto processamos sua solicitação.");
    }
    if (LocaleSnap.language === "pl") {
      setWaitMessage("Twoja prośba została wysłana do GPT3. Proszę czekać, przetwarzamy Twoje żądanie.");
    }
    setReRenderFlag(true);
  }, [LocaleSnap.language]);

  // Re-render the chat history when the language changes with a new welcome message
  useEffect(() => {
    if (reRenderFlag) {
      setChatHistory((prev) => [...prev, { role: 'system', content: welcomeContent }]);
      setReRenderFlag(false);
    }
  }, [reRenderFlag]);

  // Handler to handle minimize button. (Minimizes the chatbot window)
  const handleChatbotIconClick = () => {
    setShowChatbox(!showChatbox);
    if (showChatbox) {
      scrollToBottom();
    } 
  };

  // Scroll to the bottom of the chatbox when the chat history changes
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Send user input to backend API. And add the user input and response from backend to chat history.
  // Disable send button while processing current request. Avoid sending multiple requests from the same user at the same time.
  const sendMessage = async () => {
    if (!inputValue) return;
    // Add the user message to the chat history
    const userMessage = {
      role: 'user',
      content: inputValue,
    };
    // Reset the input value after sending the message
    setInputValue('');
    setChatHistory((prev) => [...prev, userMessage]);
    setIsProcessing(true);
    // Add prompt to the user message 
    const userMessage_gpt = {
      role: 'user',
      content: "You are a service chatbot from Xylem and now chatting with a user, provide them with comprehensive, short and concise answers. " + inputValue,
    };
    
    // Send the user input to the OpenAI API
    let botReply = "";
    try {
      const timeout = setTimeout(() => {
        const intermediateMessage = {
          role: 'system',
          content: waitMessage,
        };
        setChatHistory((prev) => [...prev, intermediateMessage]);
      }, 5000);
      await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify([...chatHistory, userMessage_gpt])
        })
        .then(response => response.json())
        .then(data => {
          clearTimeout(timeout);
          botReply = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      const botMessage = {
        role: 'system',
        content: botReply,
      };
      // Add the bot reply to the chat history
      setChatHistory((prev) => [...prev, botMessage]);    
    } catch (error) {
      botReply = "Failed to send message. Could not process request. Try again later.";
      setChatHistory((prev) => [...prev, { role: 'system', content: 'Failed to send message. Could not process request. Try again later.' }]);
    }
    setIsProcessing(false);
  };

  // Button click handle
  const handleFormSubmit = (event) => {
    event.preventDefault();
    sendMessage();
  };
  
  // Scroll to the bottom of the chatbox
  const scrollToBottom = () => {
    setTimeout(() => {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }, 0);
  };

  // Conditional rendering of the chatbox
  return (
    <div key={reRenderFlag} className={`chatbox-container ${showChatbox ? "minimized" : ""}`}>
      {!showChatbox ? (
        <>
          <div className="chatbox-header">
            <h3>Xylem AI GPT3</h3>
            <button className="minimize-button" onClick={handleChatbotIconClick}>
              -
            </button>
          </div>
          <div className="chatbox-messages" ref={chatboxRef}>
          {chatHistory.map((message, index) => (
            <p key={index} className={message.role === 'user' ? 'user-message' : 'system-message'}>
              <span className="message-box">{message.content}</span> 
            </p>
          ))}
        </div>
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type a message..."
          />
          <button className="send-button" type="submit" disabled={isProcessing}>{isProcessing? <Loader className="spinner" /> : "Send"}</button>
        </form>
        </>
      ) : (
        <ChatbotIcon onClick={handleChatbotIconClick} />
      )}
    </div>
  );
};

export default Chatbox;
