import { useState, useEffect } from "react";
import "./styles.css";
import { Accessories } from "../Accessories";
import { WholesaleButton } from "../WholesaleButton";

export const SegmentedTabs = (props) => {
  const [activeTab, setActiveTab] = useState();
  const wholesaler = props.wholesaler || [];
  const pumpId = props.pumpId;

  useEffect(() => {
    if (props?.active) {
      setActiveTab(props.active);
    }
  }, [props]);

  const setOrToggle = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
    } else {
      setActiveTab(tab);
    }
  };

  if (!props.children) return null;
  const tabs =
    props.children instanceof Array
      ? props.children.filter((c) => typeof c?.props?.name === "string")
      : [props.children];

  const showWholesaler = () => {
    if (wholesaler.length > 0) {
      return wholesaler.map((w, idx) => (
        <WholesaleButton
          wholesalerURL={w.url}
          logoName={w.logo}
          pumpId={pumpId}
          pumpClicked={"pumpClicked"}
          key={"wholesale-button-" + idx}
        />
      ));
    }
  };

  return (
    <div className={props.className || ""}>
      <div className="segmentedtabsBar">
        <div className="segmentedtabsBarLeft">
          {tabs.map((tab, index) => {
            return (
              <div
                className={`segmentedtabsLink link ${
                  tab.props.name === activeTab ? "active" : ""
                }`}
                key={index}
                onClick={() => setOrToggle(tab.props.name)}
              >
                {tab.props.name}
              </div>
            );
          })}
          <Accessories />
        </div>
        <div className="segmentedtabsBarRight">{showWholesaler()}</div>
      </div>
      {tabs.filter((c) => c.props.name === activeTab)}
    </div>
  );
};

export const Tab = (props) => {
  if (!props.children) return null;
  return <div className="tab">{props.children}</div>;
};
