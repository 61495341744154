import React from "react";
import ahlsellLogo from "../asset/logo_ahlsell.svg";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  distributorClick,
  adapterClick,
  accessoryClick,
} from "../api/userAnalytics";
import { SelectionState } from "../store";

export const Ahlsell = (props) => {
  const distributor = Object.values(props.distributor);
  const pumpId = props.pumpId;
  const originalPumpId = SelectionState.selectedPump;
  const adapter = props.adapter;
  const accessoryId = props.accessoryId;
  let firstValue, secondValue;

  const ahlsellClick = (firstValue, secondValue) => {
    if (props.pumpClicked) {
      firstValue = pumpId;
      secondValue = originalPumpId;
      distributorClick(firstValue, secondValue);
    }
    if (props.adapterClicked) {
      firstValue = adapter;
      secondValue = pumpId;
      adapterClick(firstValue, secondValue);
    }
    if (props.accessoryClicked) {
      firstValue = accessoryId;
      secondValue = pumpId;
      accessoryClick(firstValue, secondValue);
    }
  };
  return (
    <div
      className="hideOnPrint ahlsellButtonMobile"
      style={
        props.mobileStyling
          ? props.mobileStyling
          : { width: "max-content", marginLeft: "auto" }
      }
    >
      <a
        href={distributor}
        target="_blank"
        rel="noreferrer"
        onClick={
          (e) => {
            ahlsellClick(firstValue, secondValue);
          }
          // !adapter
          //   ? (e) => distributorClick(pumpId, originalPumpId)
          //   : (e) => adapterClick(adapter, pumpId)
        }
        className="Ahlsell "
      >
        <img
          className="ahlsellImg"
          style={
            props.desktopStyling
              ? props.desktopStyling
              : { height: "1rem", margin: "0rem" }
          }
          src={ahlsellLogo}
          alt="Ahlsell logo"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <AiOutlineShoppingCart className="cart" />
          <p className="ahlsellText">Köp nu</p>
        </div>
      </a>
    </div>
  );
};
