import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { observer } from "mobx-react";

import upArrow from "../asset/up_arrow.svg";
import downArrow from "../asset/down_arrow.svg";

import { useSnapshot } from "valtio";
import {
  LocaleState,
  MetadataState,
  fetchBrandsAndPumps,
  fetchPumpData,
} from "../store";

export const LanguageSelection = observer((props) => {
  const LocaleSnap = useSnapshot(LocaleState);
  const MetadataSnap = useSnapshot(MetadataState);
  const [expandedMarkets, setExpandedMarkets] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const changeSettings = (country, language) => {
    changeCountry(country);

    changeLanguage(language);

    props.toggleModal();
  };
  const changeCountry = (country) => {
    if (country === "SE") {
      localStorage.setItem("country", "SE");
      MetadataState.country = "SE";
    }
    if (country === "DE") {
      localStorage.setItem("country", "DE");
      MetadataState.country = "DE";
    }
    if (country === "CH") {
      localStorage.setItem("country", "CH");
      MetadataState.country = "CH";
    }
    if (country === "AT") {
      localStorage.setItem("country", "AT");
      MetadataState.country = "AT";
    }
    if (country === "NL") {
      localStorage.setItem("country", "NL");
      MetadataState.country = "NL";
    }
    if (country === "EN") {
      localStorage.setItem("country", "EN");
      MetadataState.country = "EN";
    }
    if (country === "IE") {
      localStorage.setItem("country", "IE");
      MetadataState.country = "IE";
    }
    if (country === "ES") {
      localStorage.setItem("country", "ES");
      MetadataState.country = "ES";
    }
    if (country === "PT") {
      localStorage.setItem("country", "PT");
      MetadataState.country = "PT";
    }
    if (country === "CZ") {
      localStorage.setItem("country", "CZ");
      MetadataState.country = "CZ";
    }
    if (country === "SK") {
      localStorage.setItem("country", "SK");
      MetadataState.country = "SK";
    }
    if (country === "GR") {
      localStorage.setItem("country", "GR");
      MetadataState.country = "GR";
    }
    if (country === "AL") {
      localStorage.setItem("country", "AL");
      MetadataState.country = "AL";
    }
    if (country === "IL") {
      localStorage.setItem("country", "IL");
      MetadataState.country = "IL";
    }
    if (country === "RO") {
      localStorage.setItem("country", "RO");
      MetadataState.country = "RO";
    }
    if (country === "PL") {
      localStorage.setItem("country", "PL");
      MetadataState.country = "PL";
    }
    if (country === "HU") {
      localStorage.setItem("country", "HU");
      MetadataState.country = "HU";
    }
    if (country === "UA") {
      localStorage.setItem("country", "UA");
      MetadataState.country = "UA";
    }
    if (country === "EE") {
      localStorage.setItem("country", "EE");
      MetadataState.country = "EE";
    }
    if (country === "LV") {
      localStorage.setItem("country", "LV");
      MetadataState.country = "LV";
    }
    if (country === "LT") {
      localStorage.setItem("country", "LT");
      MetadataState.country = "LT";
    }
    if (country === "AZ") {
      localStorage.setItem("country", "AZ");
      MetadataState.country = "AZ";
    }
    if (country === "AM") {
      localStorage.setItem("country", "AM");
      MetadataState.country = "AM";
    }
    if (country === "GE") {
      localStorage.setItem("country", "GE");
      MetadataState.country = "GE";
    }
    if (country === "KZ") {
      localStorage.setItem("country", "KZ");
      MetadataState.country = "KZ";
    }
    if (country === "KG") {
      localStorage.setItem("country", "KG");
      MetadataState.country = "KG";
    }
    if (country === "TJ") {
      localStorage.setItem("country", "TJ");
      MetadataState.country = "TJ";
    }
    if (country === "TM") {
      localStorage.setItem("country", "TM");
      MetadataState.country = "TM";
    }
    if (country === "UZ") {
      localStorage.setItem("country", "UZ");
      MetadataState.country = "UZ";
    }
    if (country === "BA") {
      localStorage.setItem("country", "BA");
      MetadataState.country = "BA";
    }
    if (country === "BG") {
      localStorage.setItem("country", "BG");
      MetadataState.country = "BG";
    }
    if (country === "HR") {
      localStorage.setItem("country", "HR");
      MetadataState.country = "HR";
    }
    if (country === "XK") {
      localStorage.setItem("country", "XK");
      MetadataState.country = "XK";
    }
    if (country === "MK") {
      localStorage.setItem("country", "MK");
      MetadataState.country = "MK";
    }
    if (country === "MD") {
      localStorage.setItem("country", "MD");
      MetadataState.country = "MD";
    }
    if (country === "ME") {
      localStorage.setItem("country", "ME");
      MetadataState.country = "ME";
    }
    if (country === "RS") {
      localStorage.setItem("country", "RS");
      MetadataState.country = "RS";
    }
    if (country === "SI") {
      localStorage.setItem("country", "SI");
      MetadataState.country = "SI";
    }
    if (country === "FI") {
      localStorage.setItem("country", "FI");
      MetadataState.country = "FI";
    }
    if (country === "NO") {
      localStorage.setItem("country", "NO");
      MetadataState.country = "NO";
    }
    if (country === "FR") {
      localStorage.setItem("country", "FR");
      MetadataState.country = "FR";
    }

    fetchBrandsAndPumps();
    fetchPumpData();
  };

  const changeLanguage = (language) => {
    if (language === "de") {
      localStorage.setItem("language", "de");
      LocaleState.language = "de";
    }

    if (language === "sv") {
      localStorage.setItem("language", "sv");
      LocaleState.language = "sv";
    }

    if (language === "en") {
      localStorage.setItem("language", "en");
      LocaleState.language = "en";
    }
    if (language === "nl") {
      localStorage.setItem("language", "nl");
      LocaleState.language = "nl";
    }
    if (language === "es") {
      localStorage.setItem("language", "es");
      LocaleState.language = "es";
    }
    if (language === "pt") {
      localStorage.setItem("language", "pt");
      LocaleState.language = "pt";
    }
    if (language === "pl") {
      localStorage.setItem("language", "pl");
      LocaleState.language = "pl";
    }
    if (language === "no") {
      localStorage.setItem("language", "no");
      LocaleState.language = "no";
    }
    if (language === "fr") {
      localStorage.setItem("language", "fr");
      LocaleState.language = "fr";
    }
  };
  return (
    <div className="ModalWrapper">
      <div className="ModalSelection">
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div className="modalDivider">
          <div className="modalDropdown">
            <p className="title is-4">Select country</p>
            <select
              onClick={() => setExpandedMarkets(!expandedMarkets)}
              onBlur={() => setExpandedMarkets(false)}
              id="countrySelect"
              className="langSelect"
              defaultValue={MetadataSnap.country}
              style={{
                backgroundImage: expandedMarkets
                  ? `url(${upArrow})`
                  : `url(${downArrow})`,
              }}
            >
              <optgroup label="Eastern Europe">
                <option value="CZ">Czech Republic </option>
                <option value="SK">Slovakia </option>
                <option value="PL">Poland </option>
                <option value="HU">Hungary </option>
                <option value="UA">Ukraine </option>
                <option value="BG">Bulgaria </option>
                <option value="RO">Romania </option>
                <option value="MD">Moldova </option>
                <option value="KZ">Kazakhstan </option>
              </optgroup>

              <optgroup label="Western Europe">
                <option value="DE">Germany</option>
                <option value="NL">Netherlands</option>
                <option value="AT">Austria</option>
                <option value="CH">Switzerland</option>
                <option value="FR">France</option>
              </optgroup>

              <optgroup label="Northern Europe">
                <option value="EN">England</option>
                <option value="IE">Ireland</option>
                <option value="SE">Sweden</option>
                <option value="LV">Lativa </option>
                <option value="EE">Estonia </option>
                <option value="LT">Lithuania </option>
                <option value="FI">Finland </option>
                <option value="NO">Norway </option>
              </optgroup>

              <optgroup label="Southern Europe">
                <option value="ES">Spain</option>
                <option value="PT">Portugal</option>
                <option value="BA">Bosnia-Hercegovina </option>
                <option value="GR">Greece </option>
                <option value="AL">Albania </option>
                <option value="ME">Montenegro </option>
                <option value="RS">Serbia </option>
                <option value="SI">Slovenia </option>
                <option value="HR">Croatia </option>
                <option value="XK">Kosovo </option>
                <option value="MK">Macedonia </option>
              </optgroup>

              <optgroup label="Middle East">
                <option value="IL">Israel </option>
                <option value="AZ">Azerbaijan </option>
                <option value="AM">Armenia </option>
                <option value="GE">Georgia </option>
              </optgroup>

              <optgroup label="Central Asia">
                <option value="KG">Kyrgystan </option>
                <option value="TJ">Tajikistan </option>
                <option value="TM">Turkmenistan </option>
                <option value="UZ">Uzbekistan </option>
              </optgroup>
            </select>
          </div>
          <div className="modalDropdown">
            <p className="title is-4">Select language</p>
            <select
              onClick={() => setExpanded(!expanded)}
              onBlur={() => setExpanded(false)}
              id="langSelect"
              className="langSelect"
              defaultValue={LocaleSnap.language}
              style={{
                backgroundImage: expanded
                  ? `url(${upArrow})`
                  : `url(${downArrow})`,
              }}
            >
              <option value="nl">Dutch</option>
              <option value="en">English</option>
              <option value="de">German</option>
              <option value="sv">Swedish</option>
              <option value="es">Spanish</option>
              <option value="pt">Portuguese</option>
              <option value="pl">Polish</option>
              <option value="no">Norwegian</option>
              <option value="fr">French</option>
            </select>
          </div>
        </div>
        <button
          className="ModalButton"
          type="submit"
          onClick={() =>
            changeSettings(
              document.getElementById("countrySelect").value,
              document.getElementById("langSelect").value
            )
          }
        >
          Next
        </button>
      </div>
    </div>
  );
});
