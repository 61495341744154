import React from "react";

import { useSnapshot } from "valtio";

import { MetadataState, ViewState } from "../store";
import { Ahlsell } from "./Ahlsell";

const formatRSK = (rsk) => {
  rsk += "";
  return (
    rsk.substring(0, 3) + " " + rsk.substring(3, 5) + " " + rsk.substring(5, 7)
  );
};

const mobileStyling = {
  marginLeft: "50%",
  transform: "translate(-50%)",
  width: "max-content",
};

const desktopStyling = {
  height: "1rem",
  margin: "0rem",
  width: "max-content",
};

export const Adapters = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const ViewSnap = useSnapshot(ViewState);
  const market = MetadataSnap.country;
  const pumpId = props.pumpId;

  return (
    <div className="content adapter" style={{ paddingBottom: 0 }}>
      <table>
        <tbody>
          {props.adapters.map((adapterObject, idx) => {
            // console.log(props.adapters);
            const adapter = MetadataSnap.adapters[adapterObject.id];
            const quantity = adapterObject.quantity;
            const distributor = adapter.attributes?.distributor;
            const link = distributor || {};
            const articleNumber = adapterObject.id;
            const ahlsellLink = Object.values(link).map((se) => {
              return se;
            });
            return (
              <React.Fragment key={"adapter_" + idx}>
                {ViewSnap.width > 700 ? (
                  <>
                    <tr>
                      <th /* colSpan={adapter.rsk ? 1 : 2}*/>
                        {MetadataSnap.texts.labels.quantity}
                      </th>
                      <th /*colSpan={adapter.rsk ? 1 : 2}*/>
                        {MetadataSnap.texts.labels.description}
                      </th>
                      {adapter.rsk && <th>{MetadataSnap.texts.labels.id}</th>}
                      {adapter.rsk && market === "SE" && (
                        <th>{MetadataSnap.texts.labels.rsk}</th>
                      )}
                      {!adapter.rsk && <th></th>}
                      {!adapter.rsk && <th></th>}
                      {!adapter.rsk && market === "SE" ? <th></th> : null}
                      {distributor && market === "SE" && (
                        <th className="hideOnPrint">
                          {MetadataSnap.texts.labels.reseller}
                        </th>
                      )}
                      {articleNumber.charAt(0) !== "X" && market !== "SE" ? (
                        <th></th>
                      ) : null}

                      {adapter.image && (
                        <td rowSpan="2" className="adapterImg">
                          <img
                            src={"/api/file/" + adapter.image}
                            alt="Adapter"
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td /*colSpan={adapter.rsk ? 1 : 2}*/>{quantity}</td>
                      <td /*colSpan={adapter.rsk ? 1 : 2}*/>
                        {adapter.description}
                      </td>

                      {adapter.rsk && <td>{adapterObject.id}</td>}
                      {articleNumber.charAt(0) !== "X" && market !== "SE" ? (
                        <td></td>
                      ) : null}
                      {adapter.rsk && market === "SE" && (
                        <td>{formatRSK(adapter.rsk)}</td>
                      )}
                      {!adapter.rsk && <td></td>}
                      {!adapter.rsk && <td></td>}
                      {!adapter.rsk && market === "SE" ? <td></td> : null}
                      {distributor && market === "SE" && (
                        <td className="hideOnPrint">
                          <Ahlsell
                            distributor={ahlsellLink}
                            adapter={adapterObject.id}
                            desktopStyling={desktopStyling}
                            mobileStyling={mobileStyling}
                            pumpId={pumpId}
                            adapterClicked={"adapterClicked"}
                          />
                        </td>
                      )}
                    </tr>
                  </>
                ) : (
                  <React.Fragment>
                    <tr>
                      <th>{MetadataSnap.texts.labels.quantity}</th>
                      <td>{quantity}</td>
                    </tr>
                    <tr>
                      <th>{MetadataSnap.texts.labels.description}</th>
                      <td>{adapter.description}</td>
                    </tr>
                    <tr>
                      {adapter.rsk && <th>{MetadataSnap.texts.labels.id}</th>}
                      {adapter.rsk && <td>{adapterObject.id}</td>}
                    </tr>
                    <tr>
                      {adapter.rsk && market === "SE" && (
                        <th>{MetadataSnap.texts.labels.rsk}</th>
                      )}
                      {adapter.rsk && market === "SE" && (
                        <td>{formatRSK(adapter.rsk)}</td>
                      )}
                    </tr>
                    {distributor && market === "SE" && (
                      <tr>
                        <th className="adapterDivider">
                          {MetadataSnap.texts.labels.reseller}
                        </th>
                        <td className="adapterDivider">
                          <Ahlsell
                            distributor={ahlsellLink}
                            adapter={adapterObject.id}
                            mobileStyling={mobileStyling}
                            pumpId={pumpId}
                            adapterClicked={"adapterClicked"}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
