import React from "react";
import { useSnapshot } from "valtio";

import { PumpState, MetadataState } from "../store";

import { BsExclamation } from "react-icons/bs";

export const Disclaimer = () => {
  const PumpSnap = useSnapshot(PumpState);
  const MetadataSnap = useSnapshot(MetadataState);

  return !MetadataSnap?.texts?.general?.noReplacementFound ? null : (
    <div>
      {PumpSnap.replacementPumps.length === 0 && PumpSnap.existingPump ? (
        <div className="card pump-card">
          <div className="disclaimer">
            <BsExclamation
              className="warningSign"
              style={{ marginBottom: "-10px" }}
            />

            {MetadataSnap.texts.general.noReplacementFound}

            <div style={{ paddingTop: "10px" }}>
              📧:
              <a href={`mailto:${MetadataSnap.email}`}>{MetadataSnap.email}</a>
            </div>
            <div>
              ☎️:
              <a href={`tel:${MetadataSnap.phone}`}>{MetadataSnap.phone}</a>
            </div>
          </div>
        </div>
      ) : PumpSnap.existingPump ? (
        <div className="card pump-card">
          <div className="disclaimer">
            <BsExclamation
              className="warningSign"
              style={{ marginBottom: "-10px" }}
            />
            {MetadataSnap.texts.general.disclaimer.prelude}

            <ul className="bulletPoints">
              <li>{MetadataSnap.texts.general.disclaimer.item1}</li>
              <li>{MetadataSnap.texts.general.disclaimer.item2}</li>
              <li>{MetadataSnap.texts.general.disclaimer.item3}</li>
              <li>{MetadataSnap.texts.general.disclaimer.item4}</li>
              <li>{MetadataSnap.texts.general.disclaimer.item5}</li>
            </ul>
            {MetadataSnap.texts.general.disclaimer.contact}
            <div style={{ paddingTop: "10px" }}>
              📧:
              <a href={`mailto:${MetadataSnap.email}`}>{MetadataSnap.email}</a>
            </div>
            <div>
              ☎️:
              <a href={`tel:${MetadataSnap.phone}`}>{MetadataSnap.phone}</a>
            </div>
          </div>
        </div>
      ) : (
        <div className="contactus">
          <div style={{ paddingTop: "10px" }}>
            📧:
            <a href={`mailto:${MetadataSnap.email}`}>{MetadataSnap.email}</a>
          </div>

          <div>
            ☎️:
            <a href={`tel:${MetadataSnap.phone}`}>{MetadataSnap.phone}</a>
          </div>
        </div>
      )}
    </div>
  );
};
