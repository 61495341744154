import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";

import { AiFillPrinter } from "react-icons/ai";
import { IoIosGlobe } from "react-icons/io";

import logo from "../asset/xylem_logo.svg";
import wave from "../asset/wave.svg";
import waveAqua from "../asset/waveAqua.svg";
import pump from "../asset/old_xylem_pump.png";
import { LanguageSelection } from "./LanguageSelection";
import { LocaleState, MetadataState } from "../store";
import { useHistory } from "react-router-dom";

export const Header = () => {
  const LocaleSnap = useSnapshot(LocaleState);
  const MetadataSnap = useSnapshot(MetadataState);

  const [languageModal, setLanguageModal] = useState(false);
  const [markets, setMarkets] = useState("");
  const [language, setLanguage] = useState("");

  const headlines = {
    sv: "Världens största utbytesguide för pumpar",
    de: "Die weltgrößte Austauschliste für Pumpen",
    es: "La mayor guía de sustitución de bombas del mundo",
    pt: "O maior guia de substituição para bombas do mundo",
    pl: "Przewodnik zamiany pomp",
    no: "Verdens største erstatningsguide for pumper",
    fr: "Le plus important guide de remplacement de pompes au monde",
  };

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const history = useHistory();

  const toggleModal = () => {
    setLanguageModal(!languageModal);
  };

  useEffect(() => {
    if (MetadataSnap.country === "SE") {
      setMarkets("Sweden");
    }
    if (MetadataSnap.country === "DE") {
      setMarkets("Germany");
    }
    if (MetadataSnap.country === "CH") {
      setMarkets("Switzerland");
    }
    if (MetadataSnap.country === "AT") {
      setMarkets("Austria");
    }
    if (MetadataSnap.country === "NL") {
      setMarkets("Netherlands");
    }
    if (MetadataSnap.country === "EN") {
      setMarkets("England");
    }
    if (MetadataSnap.country === "IE") {
      setMarkets("Ireland");
    }
    if (MetadataSnap.country === "ES") {
      setMarkets("Spain");
    }
    if (MetadataSnap.country === "PT") {
      setMarkets("Portugal");
    }
    if (MetadataSnap.country === "CZ") {
      setMarkets("Czech Republic");
    }
    if (MetadataSnap.country === "SK") {
      setMarkets("Slovakia");
    }
    if (MetadataSnap.country === "GR") {
      setMarkets("Greece");
    }
    if (MetadataSnap.country === "AL") {
      setMarkets("Albania");
    }
    if (MetadataSnap.country === "IL") {
      setMarkets("Israel");
    }
    if (MetadataSnap.country === "RO") {
      setMarkets("Romania");
    }
    if (MetadataSnap.country === "PL") {
      setMarkets("Poland");
    }
    if (MetadataSnap.country === "HU") {
      setMarkets("Hungary");
    }
    if (MetadataSnap.country === "UA") {
      setMarkets("Ukraine");
    }
    if (MetadataSnap.country === "EE") {
      setMarkets("Estonia");
    }
    if (MetadataSnap.country === "LV") {
      setMarkets("Latvia");
    }
    if (MetadataSnap.country === "LT") {
      setMarkets("Lithuania");
    }
    if (MetadataSnap.country === "AZ") {
      setMarkets("Azerbaijan");
    }
    if (MetadataSnap.country === "AM") {
      setMarkets("Armenia");
    }
    if (MetadataSnap.country === "GE") {
      setMarkets("Georgia");
    }
    if (MetadataSnap.country === "KZ") {
      setMarkets("Kazakhstan");
    }
    if (MetadataSnap.country === "KG") {
      setMarkets("Kyrgystan");
    }
    if (MetadataSnap.country === "TJ") {
      setMarkets("Tajikistan");
    }
    if (MetadataSnap.country === "TM") {
      setMarkets("Turkmenistan");
    }
    if (MetadataSnap.country === "UZ") {
      setMarkets("Uzbekistan");
    }
    if (MetadataSnap.country === "BA") {
      setMarkets("Bosnia-Hercegovina");
    }
    if (MetadataSnap.country === "BG") {
      setMarkets("Bulgaria");
    }
    if (MetadataSnap.country === "HR") {
      setMarkets("Croatia");
    }
    if (MetadataSnap.country === "XK") {
      setMarkets("Kosovo");
    }
    if (MetadataSnap.country === "MK") {
      setMarkets("Macedonia");
    }
    if (MetadataSnap.country === "MD") {
      setMarkets("Moldova");
    }
    if (MetadataSnap.country === "ME") {
      setMarkets("Montenegro");
    }
    if (MetadataSnap.country === "RS") {
      setMarkets("Serbia");
    }
    if (MetadataSnap.country === "SI") {
      setMarkets("Slovenia");
    }
    if (MetadataSnap.country === "FI") {
      setMarkets("Finland");
    }
    if (MetadataSnap.country === "NO") {
      setMarkets("Norway");
    }
    if (MetadataSnap.country === "FR") {
      setMarkets("France");
    }
  }, [MetadataSnap.country]);

  useEffect(() => {
    if (LocaleSnap.language === "en") {
      setLanguage("English");
    }
    if (LocaleSnap.language === "sv") {
      setLanguage("Swedish");
    }
    if (LocaleSnap.language === "de") {
      setLanguage("German");
    }
    if (LocaleSnap.language === "nl") {
      setLanguage("Dutch");
    }
    if (LocaleSnap.language === "es") {
      setLanguage("Spanish");
    }
    if (LocaleSnap.language === "pt") {
      setLanguage("Portuguese");
    }
    if (LocaleSnap.language === "pl") {
      setLanguage("Polish");
    }
    if (LocaleSnap.language === "no") {
      setLanguage("Norwegian");
    }
    if (LocaleSnap.language === "fr") {
      setLanguage("French");
    }
  }, [LocaleSnap.language]);

  const toHome = () => {
    history.push("/");
    window.location.reload();
  };

  return (
    <div>
      <header className="App-header">
        <div className="wave-top">
          <img
            src={logo}
            className="App-logo"
            alt="Xylem logo"
            onClick={(e) => toHome()}
          />
          <img className="wave" src={wave} alt="xylem wave" />
        </div>
        <div className="wave-bottom">
          <div className="welcomeText">
            <img src={pump} className="oldPump" alt="old xylem pump" />
            <p>
              <b>XylemPump.ID</b> -
              {headlines.hasOwnProperty(LocaleSnap.language)
                ? headlines[LocaleSnap.language]
                : "The world's largest replacement guide for pumps"}
            </p>
          </div>

          <img className="wave" src={waveAqua} alt="xylem wave" />
        </div>
        <section className="topBar">
          <div
            className="selectLang"
            onClick={() => {
              setLanguageModal(true);
            }}
          >
            <IoIosGlobe className="globeIcon" />
            <p>
              {markets} : {language}
            </p>
          </div>

          <AiFillPrinter
            className="printIcon"
            onClick={() => {
              window.print();
            }}
          />
        </section>
      </header>
      {languageModal && <LanguageSelection toggleModal={toggleModal} />}
    </div>
  );
};
