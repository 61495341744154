import React, { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import Fuse from "fuse.js";

import upArrow from "../asset/up_arrow.svg";
import downArrow from "../asset/down_arrow.svg";
import { HiOutlineSearch } from "react-icons/hi";

import { MetadataState, SelectionState, LocaleState } from "../store";

import { sendPumpId } from "../api/userAnalytics";

import { useHistory, useParams } from "react-router-dom";

const MAXRESULTS = 200;

const fuseOptions = {
  threshold: 0.4,
  distance: 50,
  keys: ["searchName", "rsk", "name"],
  includeScore: true,
};

export const SearchArea = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [matchedPumps, setMatchedPumps] = useState([]);
  const [placeholder, setPlaceholder] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const MetadataSnap = useSnapshot(MetadataState);
  const LocaleSnap = useSnapshot(LocaleState);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) getPumpFromUrl(id);
  }, [id]);

  const getPumpFromUrl = (id) => {
    SelectionState.selectedPump = id;
  };

  const selectPump = async (pumpId, brand, name) => {
    sendPumpId(pumpId);
    setExpanded(false);
    setPlaceholder(brand + " " + name);
    searchPump(pumpId, brand, name);
  };

  const searchPump = (pumpId, brand, name) => {
    let arr = [...brand].map((char) => {
      if (char === "å") {
        return "a";
      }
      if (char === "ä") {
        return "a";
      }
      if (char === "ö") {
        return "o";
      }
      if (char === "Å") {
        return "A";
      }
      if (char === "Ä") {
        return "A";
      }
      if (char === "Ö") {
        return "O";
      } else {
        return char;
      }
    });

    let brandName = arr.join("");
    const urlName =
      brandName
        .trim()
        .replace(/([^\w]+|\s+)/g, "-")
        .toLowerCase() +
      "-" +
      name
        .trim()
        .replace(/([^\w]+|\s+)/g, "-")
        .toLowerCase();

    history.push(
      `/${
        LocaleSnap.language + "-" + MetadataSnap.country
      }/${urlName}/${pumpId}`
    );
  };

  useEffect(() => {
    const urlParams = window.location.pathname.split("/").toString();
    const urlBrand = urlParams.split(",").slice(2, 3);
    const urlPump = urlParams.split(",").slice(3, 4);

    if (urlBrand.toString() === "") {
      history.push(`/${LocaleSnap.language + "-" + MetadataSnap.country}/`);
    } else {
      history.push(
        `/${
          LocaleSnap.language + "-" + MetadataSnap.country.toUpperCase()
        }/${urlBrand}/${urlPump}`
      );
    }
  }, [LocaleSnap.language, MetadataSnap.country]);

  const search = (brand) => {
    const filteredPumps = MetadataSnap.pumps
      .filter((pump) => pump.brand === brand || brand === null)
      .map((pump) => ({
        ...pump,
        searchName: MetadataSnap.brands[pump.brand] + " " + pump.name,
      }));

    const fuse = new Fuse(filteredPumps, fuseOptions);

    // setExpanded(true);
    const fussy = fuse.search(document.querySelector("#fuse").value);

    setMatchedPumps(
      fussy.length
        ? fussy.slice(0, MAXRESULTS)
        : filteredPumps.length !== MetadataSnap.pumps.length
        ? filteredPumps.slice(0, MAXRESULTS)
        : []
    );
  };
  const input = document.getElementById("fuse");

  const checkLength = () => {
    if (input.value.length > 0) {
      setExpanded(true);
    }
    if (input.value.length <= 0) {
      setExpanded(false);
    }
  };

  let goodMatch = false;
  let perfectMatch = false;

  return (
    <div id="search-area">
      <div id="select-filters">
        <select
          id="filter-manufacturer"
          onChange={(event) => {
            let brand = event.target.value;
            brand = brand === "null" ? null : Number(brand);
            setSelectedBrand(brand);
            search(brand);
          }}
          onClick={() => setExpandedFilter(!expandedFilter)}
          onBlur={() => setExpandedFilter(false)}
          style={{
            backgroundImage: expandedFilter
              ? `url(${upArrow})`
              : `url(${downArrow})`,
          }}
        >
          <option value="null">{MetadataSnap.texts?.general?.brand}</option>
          {Object.entries(MetadataSnap.brands)
            .filter(([k, v]) => Number(k) < 100)
            .map(([k, v], idx) => (
              <option key={idx} value={k}>
                {v}
              </option>
            ))}
        </select>
      </div>
      <div className="search-form">
        <button type="submit">
          <HiOutlineSearch />
        </button>

        <input
          type="search"
          autoComplete="off"
          id="fuse"
          placeholder={
            placeholder || MetadataSnap.texts?.general?.searchPlaceholder
          }
          onChange={() => search(selectedBrand)}
          onInput={() => checkLength()}
          onFocus={() => checkLength()}
        />
        <button className="show-list" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <img src={upArrow} className="toggleArrow" alt="Up arrow" />
          ) : (
            <img src={downArrow} className="toggleArrow" alt="Down arrow" />
          )}
        </button>
      </div>
      <div id="search-list" className={expanded ? "active" : ""}>
        {matchedPumps.map((obj, index) => {
          const pump = obj?.item || obj;

          const length = input.value.length;
          const score = (
            Number.parseFloat(obj.score) / Math.sqrt(length)
          ).toFixed(5);

          let className;
          if (score <= 0.0001 && input.value.length >= 2) {
            // Perfect match
            perfectMatch = true;
            goodMatch = true;
          } else if (
            !perfectMatch &&
            score <= 0.008 &&
            input.value.length >= 6
          ) {
            // Good match
            goodMatch = true;
          } else if (goodMatch) {
            // Blur bad matches
            className = "blurred";
          }

          return (
            <div
              key={index}
              className={"search-item " + className}
              onClick={() =>
                selectPump(
                  pump?.id,
                  MetadataSnap.brands[pump?.brand],
                  pump?.name
                )
              }
            >
              <b className="search-result manufacturer">
                {MetadataSnap.brands[pump?.brand]}
              </b>
              <span className={"search-result name"}>{pump?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
