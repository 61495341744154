import React, { useState } from "react";
import { setInitialValues } from "../api/userAnalytics";

export const CookieDisclaimer = () => {
  const [cookies, setCookies] = useState(localStorage.getItem("cookies"));

  const AcceptCookies = () => {
    localStorage.setItem("cookies", "accepted");
    setCookies("accepted");
    setInitialValues();
  };
  if (cookies === "accepted") return null;
  return (
    <div className="ModalWrapper">
      <div className="ModalSelection">
        <h1>Cookie</h1>
        <p>
          We use cookies and related tracking technologies to personalize and
          enhance your experience on our site. By clicking 'Accept' you agree to
          the use of cookies and related tracking technologies
        </p>
        <button className="ModalButton" onClick={AcceptCookies}>
          Accept
        </button>
      </div>
    </div>
  );
};
