import { React, useState } from "react";
import logo from "./asset/xylem_logo.svg";

import "./App.css";
import "./App.sass";
import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

import { BsExclamation } from "react-icons/bs";

import { SegmentedTabs, Tab } from "./components/SegmentedTabs";
import { Datasheet } from "./components/Datasheet";
import { Pump } from "./components/Pump";
import { Adapters } from "./components/Adapters";
import { Accessories } from "./components/Accessories";

import { LocaleState, MetadataState, PumpState, ViewState } from "./store";

import { useSnapshot } from "valtio";

import { Header } from "./components/Header";
import { SearchArea } from "./components/SearchArea";
import { Disclaimer } from "./components/Disclaimer";
import { CookieDisclaimer } from "./components/CookieDisclaimer";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import Chatbox from "./components/Chatbox";
import ChatbotIcon from "./components/ChatbotIcon";

const Home = () => {
  const MetadataSnap = useSnapshot(MetadataState);
  const LocaleSnap = useSnapshot(LocaleState);
  const PumpSnap = useSnapshot(PumpState);
  const ViewSnap = useSnapshot(ViewState);

  const transformPump = (pump) => {
    const newPump = {
      ...pump,
    };

    if (newPump.brand) {
      newPump.brand = MetadataSnap.brands[newPump.brand];
    }

    return newPump;
  };
  const [showChatbox, setShowChatbox] = useState(false);

  const transformExistingPump = (pump) => {
    const newPump = {
      ...pump,
    };
    if (newPump.eei) {
      delete newPump.eei;
    }
    if (newPump.brand) {
      newPump.brand = MetadataSnap.brands[newPump.brand];
    }

    return newPump;
  };

  const handleChatbotIconClick = () => {
    setShowChatbox(true);
  };

  const columns = ViewSnap.width > 600 ? 2 : 1;
  return (
    <div className="App">
      <Header />
      <SearchArea />
      <CookieDisclaimer />
      <Pump
        pump={transformExistingPump(PumpSnap.existingPump)}
        title={MetadataSnap.texts?.general?.existingPumpTable}
        translations={MetadataSnap.texts?.labels}
        columns={columns}
        style={{ breakBefore: "always" }}
      />

      {PumpSnap.replacementPumps.length > 0 &&
      PumpSnap.replacementPumps[0].replacementTypeId !== 1 ? (
        <div className="card pump-card">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">
                  {MetadataSnap.texts.general.replacementPumpTable +
                    " (Premium)"}
                </p>
                <p className="subtitle is-6" style={{ marginBottom: "1.5rem" }}>
                  <BsExclamation
                    className="warningSign"
                    style={{ marginBottom: "-10px" }}
                  />
                  {MetadataSnap.texts.general.noPremiumReplacementFound}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {PumpSnap.replacementPumps.map(transformPump).map((rp, idx) => {
        return !MetadataSnap?.texts?.general?.replacementPumpTable ? null : (
          <Pump
            key={"replacement-pump-" + idx}
            pump={rp}
            title={
              MetadataSnap.texts.general.replacementPumpTable +
              " (" +
              rp.replacementType +
              ")"
            }
            translations={MetadataSnap.texts.labels}
            columns={columns}
            images={rp.image ? ["/api/file/" + rp.image] : [logo]}
            comments={rp.comments}
          >
            <SegmentedTabs
              className="segmented-tabs"
              wholesaler={rp.wholesaler && rp.wholesaler[MetadataSnap.country]}
              pumpId={rp.id}
            >
              {rp.adapters && Object.values(rp.adapters).length > 0 ? (
                <Tab name={MetadataSnap.texts.general.adapterKit}>
                  <Adapters adapters={rp.adapters} pumpId={rp.id} />
                </Tab>
              ) : null}

              {LocaleSnap.language === "de" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=de"}
                  />
                </Tab>
              ) : null}
              {LocaleSnap.language === "es" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=es"}
                  />
                </Tab>
              ) : null}

              {LocaleSnap.language === "pt" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=pt"}
                  />
                </Tab>
              ) : null}
              {LocaleSnap.language === "pl" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=pl"}
                  />
                </Tab>
              ) : null}
              {(LocaleSnap.language === "nl" || LocaleSnap.language === "en") &&
              rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=en"}
                  />
                </Tab>
              ) : null}

              {LocaleSnap.language === "sv" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=sv"}
                  />
                </Tab>
              ) : null}
              {LocaleSnap.language === "no" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=no"}
                  />
                </Tab>
              ) : null}
              {LocaleSnap.language === "fr" && rp.datasheet ? (
                <Tab name={MetadataSnap.texts.general.productSheet}>
                  <Datasheet
                    src={"/api/file/" + rp.datasheet + "?language=fr"}
                  />
                </Tab>
              ) : null}
              {LocaleSnap.language === "sv" &&
                MetadataSnap.country === "SE" &&
                rp.accessoryNums > 0 && (
                  <Tab name="Tillbehör">
                    <Accessories pumpId={rp.id} />
                  </Tab>
                )}
            </SegmentedTabs>
          </Pump>
        );
      })}
      <Disclaimer />
      {showChatbox ? (
        <Chatbox />
      ) : (
        <ChatbotIcon onClick={handleChatbotIconClick} />
      )}
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:countrycode?/:pumpname?/:id?">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
